<template>
  <div v-if="$hasFonction(user, 'Activer chargeurs')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div v-show="add">
      <app-collapse class="test">
        <app-collapse-item
          isVisible
          title="Ajouter un chargeur"
          v-if="$hasFonction(user, 'Ajouter chargeurs')"
        >
          <!-- form start -->
          <validation-observer ref="addForm">
            <b-form class="mt-2 blockForm" @submit.prevent>
              <div class="form">
                <b-row class="formRow">
                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="defaultForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Nom et Prénom"
                        @click="resetInputName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsName }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="devise"
                      rules="required"
                    >
                      <label for="phone">Devise</label>
                      <b-form-select
                        id="devise"
                        v-model="defaultForm.devise"
                        :options="devises"
                        value-field="id"
                        text-field="name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsDevise }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col fonction">
                    <validation-provider
                      #default="{ errors }"
                      name="associations"
                    >
                      <label for="work">Associations</label>
                      <v-select
                        id="associations"
                        v-model="defaultForm.associations"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        :options="associations"
                        label="name"
                        :closeOnSelect="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{
                        errors[0] ? "" : errorsAssociations
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <!-- submit button start -->
                <div class="BlockbButton mt-2">
                  <div>
                    <b-button
                      type="reset"
                      class="mr-2 annuler"
                      variant="outline-secondary"
                      @click="resetInput"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButton"
                      type="submit"
                      variant="primary"
                      :disabled="isOperating"
                      @click.prevent="addChargeur"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                </div>
                <!-- submit button end -->
              </div>
            </b-form>
          </validation-observer>
          <!-- form end -->
        </app-collapse-item>
      </app-collapse>
    </div>

    <div :hidden="hidden">
      <app-collapse class="test">
        <app-collapse-item
          ref="collapse"
          title="Ajouter un chargeur"
          v-if="$hasFonction(user, 'Ajouter chargeurs')"
        >
          <!-- form start -->
          <validation-observer ref="addForm">
            <b-form class="mt-2 blockForm" @submit.prevent>
              <div class="form">
                <b-row class="formRow">
                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="defaultForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Nom et Prénom"
                        @click="resetInputName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsName }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="devise"
                      rules="required"
                    >
                      <label for="phone">Devise</label>
                      <b-form-select
                        id="devise"
                        v-model="defaultForm.devise"
                        :options="devises"
                        value-field="id"
                        text-field="name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsDevise }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col fonction">
                    <validation-provider
                      #default="{ errors }"
                      name="associations"
                    >
                      <label for="work">Associations</label>
                      <v-select
                        id="associations"
                        v-model="defaultForm.associations"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        :options="associations"
                        label="name"
                        :closeOnSelect="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{
                        errors[0] ? "" : errorsAssociations
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <!-- submit button start -->
                <div class="BlockbButton mt-2">
                  <div>
                    <b-button
                      type="reset"
                      class="mr-2 annuler"
                      variant="outline-secondary"
                      @click="resetInput"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButton"
                      type="submit"
                      variant="primary"
                      :disabled="isOperating"
                      @click.prevent="addChargeur"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                </div>
                <!-- submit button end -->
              </div>
            </b-form>
          </validation-observer>
          <!-- form end -->
        </app-collapse-item>
      </app-collapse>

      <b-card no-body title="" class="mt-2">
        <div class="my-2 mx-2 row">
          <!-- search start -->
          <div class="blockSearch col-lg-8">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Recherche"
              class="search col4 mr-5"
              style="width: 40%"
            >
            </b-form-input>
          </div>
          <!-- search end -->

          <!-- bouton export start -->
          <div class="col-lg-4">

            <b-button
              v-if="$hasFonction(user, 'Exporter chargeurs')"
              variant="outline-primary"
              size="sm"
              @click="ExportFomatExcel"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Exporter
            </b-button>

            <b-button
            v-if="$hasFonction(user, 'Exporter chargeurs')"
            variant="outline-primary"
            size="sm"
            class="ml-sm-2 mt-2 mt-sm-0"
            @click="ExportAllToFomatExcel"
            >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
            </b-button>

          </div>
          <!-- bouton export end -->
        </div>

        <!-- table start -->
        <b-table
          v-model="ExcelChargeurs"
          id="my-table"
          responsive="sm"
          :fields="headers"
          @filtered="onFiltered"
          :items="chargeurs"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          show-empty
          class="border-bottom"
        >
          <template #cell(associations)="data">
            <div class="d-block" v-for="asso in data.value" :key="asso.id">
              {{ asso.name }}
            </div>
          </template>
          <!-- iconAction start -->
          <template #cell(actions)="data">
            <div class="row">
              <div>
                <b-button
                  :id="'button-1' + data.item.id"
                  v-if="$hasFonction(user, 'Modifier chargeurs')"
                  size="sm"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="editChargeur(data.item)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <!-- <b-tooltip
                  :target="'button-1' + data.item.id"
                  title="Editer"
                  variant="dark"
                ></b-tooltip> -->
              </div>
              <div>
                <b-button
                  :id="'button-2' + data.item.id"
                  v-if="$hasFonction(user, 'Supprimer chargeurs')"
                  size="sm"
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deleteChargeur(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <!-- <b-tooltip
                  :target="'button-2' + data.item.id"
                  title="Supprimer"
                  variant="dark"
                ></b-tooltip> -->
              </div>
            </div>
          </template>
          <!-- iconAction end -->

          <template #empty>
            <div class="text-center">
              <span class="text-nowrap">Aucune donnée existante</span>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-center">
              <span class="text-nowrap"
                >L'élément recherché n'est pas dans la liste</span
              >
            </div>
          </template>
        </b-table>
        <!-- table end -->

        <div class="my-2 mx-2 row">
          <!-- per-page end -->
          <div class="col">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 70px"
            ></b-form-select>
            <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
            <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
          </div>
          <!-- per-page end -->

          <!-- pagination start -->
          <b-pagination
            class="pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- pagination end -->
        </div>
      </b-card>

      <!-- modal edit start  -->
      <b-modal
        id="modal-edit"
        v-model="modalEdit"
        :ok-disabled="isOperating"
        title="Modifier les informations"
        @hidden="resetModal"
        hide-footer
        centered
      >
        <!-- form start -->
        <b-form ref="editForm" @submit.stop.prevent="handleSubmit">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <label for="name">Nom et Prénom</label>
              <b-form-input
                id="name"
                placeholder="Nom et Prénom"
                type="text"
                v-model="editedForm.name"
                @click="resetInputNameModal"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsNameModal
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            invalid-feedback="Le champ Téléphone est requis"
            :state="nameState"
          >
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required"
            >
              <label for="phone">Devise</label>
              <b-form-select
                disabled
                id="devise"
                v-model="editedForm.devise"
                :options="devises"
                value-field="id"
                text-field="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsDeviseModal
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider #default="{ errors }" name="associations">
              <label for="work">Associations</label>

              <v-select
                id="associations"
                v-model="editedForm.associations"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="associations"
                label="name"
                :closeOnSelect="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsAssociationsModal
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalEdit = false"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonEdit"
              variant="primary"
              class="mt-2"
              :disabled="isOperating"
              @click="handleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
        <!-- form end -->
      </b-modal>
      <!-- modal edit end-->
    </div>

    <div :hidden="hiddenEmpty" class="text-center">
      <empty-list></empty-list>
      <b-button variant="primary" size="sm" @click="etatAdd">
        <b-icon-plus></b-icon-plus>
        AJOUTER
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import vSelect from "vue-select";

import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPencilFill,
  BIconTrashFill,
  BIconPlus,
  BIconCircleFill,
  BIconArrowClockwise,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BTooltip,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BCard,
    BTable,
    BModal,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BTooltip,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButton: true,
      etatButtonEdit: true,
      add: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      isOperating: false,
      required,
      integer,
      alpha,
      alphaDash,
      nameState: null,
      errorsName: null,
      errorsDevise: null,
      errorsAssociations: null,
      errorsNameModal: null,
      errorsDeviseModal: null,
      errorsAssociationsModal: null,
      modalEdit: false,
      // totalRows: 1,
      perPage: 10,
      currentPage: 1,
      allRows: 0,
      filter: null,
      pageOptions: [],
      headers: [
        {
          key: "name",
          label: "Nom & Prénom",
          sortable: true,
        },
        {
          key: "associations",
          label: "Assosiation",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "w-25",
        },
      ],
      chargeurs: [],
      ExcelChargeurs: null,
      editedForm: {},
      defaultForm: {
        devise: 1,
        associations: "",
      },
      associations: [],
      devises: [],
    };
  },
  mounted() {
    this.fournisseursData();
    this.getDevisesList();
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: this.chargeurs.length, text: "Tout" } ]
      return this.allRows;
    },
  },
  created() {
    this.getChargeurs();
  },
  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },


    resetForm() {
      this.defaultForm.name = "";
      this.defaultForm.devise = "";
      this.defaultForm.associations = "";
    },
    resetModal() {
      this.editedForm.name = "";
      this.editedForm.devise = "";
      this.editedForm.associations = "";
      this.resetInputModal();
    },
    resetInput() {
      this.resetInputName();
      this.resetInputdevise();
      this.resetInputAssociations();
    },
    resetInputName() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },
    resetInputDevise() {
      if (this.errorsDevise) {
        this.errorsDevise = null;
      }
    },
    resetInputAssociations() {
      if (this.errorsAssociations) {
        this.errorsAssociations = null;
      }
    },
    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputDeviseModal();
      this.resetInputAssociationsModal();
    },
    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },
    resetInputDeviseModal() {
      if (this.errorsDeviseModal) {
        this.errorsDeviseModal = null;
      }
    },
    resetInputAssociationsModal() {
      if (this.errorsAssociationsModal) {
        this.errorsAssociationsModal = null;
      }
    },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    getChargeurs() {
      this.$http
        .get("/chargeurs")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.chargeurs = res.data.data.reverse();

          if (this.chargeurs.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          this.allRows = this.chargeurs.length

        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },
    addChargeur() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.etatButton = false;
          this.$http
            .post("/chargeurs", this.defaultForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.defaultForm = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.add = false;
                this.hidden = false;
                this.showToast("Chargeur ajouté avec succès", "success");
                this.$refs.addForm.reset();
                if (this.chargeurs.length) {
                  this.$refs.collapse.updateVisible(false);
                }
                this.getChargeurs();
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;
              var err = e.response.data.errors;

              if (err) {
                if (err.name) {
                  this.errorsName = err.name[0];
                }

                if (err.devise) {
                  this.errorsDevise = err.devise[0];
                }

                if (err.associations) {
                  this.errorsAssociations = err.associations[0];
                }
              }
            });
        }
      });
    },
    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.etatButtonEdit = false;
      this.$http
        .put("/chargeurs/" + this.editedForm.id, this.editedForm)
        .then((result) => {
          // 	this.isOperating = false;
          if (result.data.success) {
            this.etatButtonEdit = true;
            this.resetInputModal();
            this.editedForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEdit = false;
            this.$refs.addForm.reset();
            this.showToast("Chargeur modifié avec succès", "success");
            this.getChargeurs();
          } else {
            this.etatButtonEdit = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEdit = true;
          console.log("erreur modification:" + e);
          if (e.response.data.data == "name") {
            this.errorsNameModal = e.response.data.message;
          }
          var err = e.response.data.errors;
          if (err) {
            if (err.name) {
              this.errorsNameModal = err.name[0];
            }

            if (err.devise) {
              this.errorsDeviseModal = err.devise[0];
            }

            if (err.associations) {
              this.errorsAssociationsModal = err.associations[0];
            }
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },
    editChargeur(item) {
      this.editedForm = Object.assign({}, item);
      this.modalEdit = true;
    },
    deleteChargeur(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des chargeurs ?",
          {
            title: "Supprimer un chargeur",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/chargeurs/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast("Supression effectuée avec succès", "success");
                  this.getChargeurs();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },
    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};
      let associations = [];

      this.ExcelChargeurs.forEach((element) => {
        element.associations.forEach((elt) => {
          associations.push(elt.name);
        });

        obj = {
          "NOM & PRÉNOM": element.name,
          ASSOSIATION: associations.join(" // "),
        };

        data.push(obj);
      });
      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Chargeurs",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    
    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};
      let associations = [];

      this.chargeurs.forEach((element) => {
        element.associations.forEach((elt) => {
          associations.push(elt.name);
        });

        obj = {
          "NOM & PRÉNOM": element.name,
          ASSOSIATION: associations.join(" // "),
        };

        data.push(obj);
      });
      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Chargeurs",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    async fournisseursData() {
      let response = await this.$http.get("/associations").then((response) => {
        this.associations = response.data.data;
      });
    },
    getDevisesList() {
      let response = this.$http.get("/devises").then((response) => {
        this.devises = response.data.data;
        // this.devise_principale = this.devises.find( ({ devise_principale}) => devise_principale === 1 )
      });
    },
  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>